/**
 * Componente de confirmacion de codigo de seguridad: Muestra el formulario del codigo de seguridad para su confirmacion
 */

import { Body, Box, Button, Description, Heading, Input, Label, Stack, StackRow, shootToast } from "codekit";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  useLoginRestoreCodeMutation,
  useSendEmailMutation,
  useLockUserMutation,
} from "../../libs/redux/slices/forgotSlice/forgotApiSlice";
import { getUser as getUserForgot, confirmCode } from "../../libs/redux/slices/forgotSlice/forgotSlice";
import { useLoginTokenMutation } from "../../libs/redux/slices/authSlice/authApiSlice";
import { getUser as getUserAuth, signToken, setTokenGenio } from "../../libs/redux/slices/authSlice/authSlice";
import { useLoginGenioMutation } from "../../libs/redux/slices/Genioxxx/genioxxxApiSlice";

const Codeconf = ({ origin }) => {
  const isLoginRequest = origin === "LOGIN-PAGE";
  const isForgotPasswordRequest = origin === "FORGOT-PAGE";

  const USRIDXXX = useSelector(isLoginRequest ? getUserAuth : getUserForgot);

  const [number1, setNumber1] = useState("");
  const [number2, setNumber2] = useState("");
  const [number3, setNumber3] = useState("");
  const [number4, setNumber4] = useState("");

  const [remainingMinutes, setRemainingMinutes] = useState(2); // Cambiar a 2 minutos
  const [remainingSeconds, setRemainingSeconds] = useState(0);

  const [remainingTimeLabel, setRemainingTimeLabel] = useState("2:00");
  const [isResendEnabled, setIsResendEnabled] = useState(false);
  const [resendAttempts, setResendAttempts] = useState(0); // Añadir contador de intentos

  const {
    register,
    handleSubmit,
    setFocus,
    formState: { isValid, errors },
  } = useForm({
    mode: "onChange",
  });

  const [loginToken, { isLoading: isLoadingLoginToken }] = useLoginTokenMutation();
  const [loginRestoreCode, { isLoading: isLoadingLoginRestoreCode }] = useLoginRestoreCodeMutation();
  const [sendEmail, { isLoading: isLoadingSendEmail }] = useSendEmailMutation();
  const [logGenio] = useLoginGenioMutation();
  const [lockUser] = useLockUserMutation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const onResend = async () => {
    if (isLoginRequest) {
      navigate("/login", { state: { clearCodeConfirmation: true } });
    }

    if (isForgotPasswordRequest) {
      try {
        await sendEmail({ email: USRIDXXX }).unwrap();
        setIsResendEnabled(false);
        setRemainingMinutes(2); // Cambiar a 2 minutos
        setRemainingSeconds(0);
        setRemainingTimeLabel("2:00");
        setResendAttempts(resendAttempts + 1); // Incrementar contador de intentos
      } catch (error) {}
    }
  };

  const onSubmit = async (formData) => {
    try {
      const { number1, number2, number3, number4 } = formData;

      const userCode = `${number1}${number2}${number3}${number4}`;

      if (isLoginRequest) {
        const userData = await loginToken({ USRIDXXX, userCode }).unwrap();
        dispatch(signToken({ USRIDXXX, userData }));
        const { data } = await logGenio({ USRIDXXX: USRIDXXX });
        if (data) {
          dispatch(setTokenGenio(data.data[0].token));
        }
      }

      if (isForgotPasswordRequest) {
        const userData = await loginRestoreCode({ USRIDXXX, userCode }).unwrap();
        dispatch(confirmCode({ USRIDXXX, userData }));
      }
    } catch (error) {}
  };

  const handleNumberInput = (e) => {
    const target = e.target;
    const { id, value } = target;
    const firstDigit = value.slice(0, 1);

    if (id === "number1") {
      setNumber1(firstDigit);
    } else if (id === "number2") {
      setNumber2(firstDigit);
    } else if (id === "number3") {
      setNumber3(firstDigit);
    } else if (id === "number4") {
      setNumber4(firstDigit);
    }
  };

  const handlePaste = (e) => {
    const clipboardData = e.clipboardData.getData("text/plain");

    if (clipboardData.length === 4 && !isNaN(clipboardData)) {
      setNumber1(clipboardData.charAt(0));
      setNumber2(clipboardData.charAt(1));
      setNumber3(clipboardData.charAt(2));
      setNumber4(clipboardData.charAt(3));
    }
  };

  const isLoading = isLoginRequest ? isLoadingLoginToken : isLoadingLoginRestoreCode;

  useEffect(() => {
    if (number1.length === 1) {
      setFocus("number2");
    }
    if (number2.length === 1) {
      setFocus("number3");
    }
    if (number3.length === 1) {
      setFocus("number4");
    }
  }, [number1, number2, number3, number4, setFocus]);

  /**
   * Hook que muestra el temporizador para reenviar el codigo de confirmacion
   */

  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (remainingSeconds === 0 && remainingMinutes === 0) {
        setIsResendEnabled(true);
      }

      if (remainingSeconds > 0) {
        setRemainingSeconds((remainingSeconds) => remainingSeconds - 1);
      } else {
        if (remainingMinutes > 0) {
          setRemainingMinutes((remainingMinutes) => remainingMinutes - 1);
          setRemainingSeconds(59);
        } else {
          clearInterval(timerInterval);
        }
      }

      const format = `${remainingMinutes}:${remainingSeconds.toString().padStart(2, "0")}`;
      setRemainingTimeLabel(format);
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [remainingMinutes, remainingSeconds]);

  useEffect(() => {
    if (resendAttempts === 3 && remainingSeconds === 0 && remainingMinutes === 0) {
      shootToast({
        variant: "error",
        text: "Su usuario ha sido bloqueado. Superó la cantidad de intentos. Por favor genere ticket a helpdesk",
        autoHide: false,
        onClose: () => {
          console.log("Toast error closed");
        },
      });

      (async () => {
        await lockUser({ USRIDXXX: USRIDXXX });
        setTimeout(() => {
          navigate("/login");
        }, 7000);
      })();
    }
  }, [resendAttempts, remainingSeconds, remainingMinutes, lockUser, USRIDXXX, navigate]);

  return (
    <Box>
      <Box textAlign="center" mb={8}>
        <Stack gap={8}>
          <Heading variant="heading-6">Código de seguridad</Heading>
          <Body variant="body-2">
            Te hemos enviado un correo electrónico con un código de seguridad. Ingresalo aquí para continuar.
          </Body>
        </Stack>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={8}>
          <StackRow>
            <Stack gap={4}>
              <Label htmlFor="" alignment="center">
                Codigo de seguridad
              </Label>
              <Stack gap={4} columns={4}>
                <Input
                  type="number"
                  disabled={isLoading}
                  isInvalid={errors.number1}
                  placeholder="0"
                  maxLength={1}
                  {...register("number1", { required: true })}
                  id="number1"
                  value={number1}
                  onChange={handleNumberInput}
                  onPaste={handlePaste}
                  isOtp
                />
                <Input
                  type="number"
                  disabled={isLoading}
                  isInvalid={errors.number2}
                  placeholder="0"
                  maxLength={1}
                  {...register("number2", { required: true })}
                  id="number2"
                  value={number2}
                  onChange={handleNumberInput}
                  onPaste={handlePaste}
                  isOtp
                />
                <Input
                  type="number"
                  disabled={isLoading}
                  isInvalid={errors.number3}
                  placeholder="0"
                  maxLength={1}
                  {...register("number3", { required: true })}
                  id="number3"
                  value={number3}
                  onChange={handleNumberInput}
                  onPaste={handlePaste}
                  isOtp
                />
                <Input
                  type="number"
                  disabled={isLoading}
                  isInvalid={errors.number4}
                  placeholder="0"
                  maxLength={1}
                  {...register("number4", { required: true })}
                  id="number4"
                  value={number4}
                  onChange={handleNumberInput}
                  onPaste={handlePaste}
                  isOtp
                />
              </Stack>

              {!isResendEnabled ? (
                <Description variant="description-3" alignment="center" color="warning-500">
                  Este código vencerá en: {remainingTimeLabel}
                </Description>
              ) : (
                ""
              )}
            </Stack>
          </StackRow>

          {isResendEnabled && (
            <Button
              type="button"
              variant="primary"
              disabled={isLoading}
              isLoading={isLoadingSendEmail}
              onClick={onResend}
              isFull
            >
              {isLoadingSendEmail ? (
                "Cargando..."
              ) : (
                <>
                  <div>{`Intento ${resendAttempts + 1} de 3`}</div>
                  <div>Volver a enviar</div>
                </>
              )}
            </Button>
          )}

          <Button type="submit" disabled={!isValid} variant="primary-bold" isLoading={isLoading} isFull>
            Validar
          </Button>
        </Stack>
      </form>
    </Box>
  );
};

export default Codeconf;
