import { useState } from "react";
import { useDispatch } from "react-redux";
import Filter from "../../../utils/Filter";
import { setFilters } from "../../../libs/redux/slices/Graficsx/graficsxSlice";

const graphFilter = {
  filterOne: {
    CAMPODES: "Clasificación",
    NOMCOLXX: "TARCYYYY.CLATARID",
    TIPCAMXX: "SELECCION",
  },
  filterTwo: {
    CAMPODES: "Responsable",
    NOMCOLXX: "TARCYYYY.TARUSRRE",
    TIPCAMXX: "SELECCION",
  },
  filterDate: {
    CAMPODES: "Fecha de Vencimiento",
    NOMCOLXX: "TARIYYYY.RESESPFX",
    TIPCAMXX: "FECHA",
  },
  filterManagement: {
    CAMPODES: "Estado de Gestión",
    NOMCOLXX: "TARCYYYY.ESTGESID",
    TIPCAMXX: "SELECCION",
  },
};

export function useFilterGraph() {
  const dispatch = useDispatch();
  const [filtersSelected, setFiltersSelected] = useState([]);

  const getValueFilter = (filterName, VALUEXXX) => {
    const valuerFilter = Object.assign({}, graphFilter[filterName]);
    valuerFilter.VALUEXXX = VALUEXXX;
    return valuerFilter;
  };

  const handleSetFiltersSelected = (filters) => {
    setFiltersSelected(filters);
  };

  const handleGetFiltersSelected = (filterName, VALUEXXX) => {
    const filterSelected = getValueFilter(filterName, VALUEXXX);
    filterSelected.VALUEXXX = VALUEXXX;

    if (filtersSelected.length === 0) {
      return [filterSelected];
    }

    const isSelected = filtersSelected.find((filter) => filterSelected.NOMCOLXX === filter.NOMCOLXX);
    if (!isSelected) {
      return [...filtersSelected, filterSelected];
    }

    const newFilters = filtersSelected.map((filter) => {
      if (filterSelected.NOMCOLXX === filter.NOMCOLXX) {
        filter.VALUEXXX = VALUEXXX;
      }
      return filter;
    });

    return newFilters;
  };

  const getTransformedFilters = (filtersToTransform) => {
    const filter = new Filter(filtersToTransform);
    const filters = filter.getFilters();
    const defaultFilters = [
      { "TARCYYYY.ESTAIDXX": { NOTIN: [101, 103] } },
      { "TARIYYYY.LIMSUPFX": { IN: ["0000-00-00"] } },
      { "TARIYYYY.LIMSUPHX": { IN: ["0000:00:00"] } },
    ];
    return [...filters, ...defaultFilters];
  };

  const handleSetFilter = (filterName, VALUEXXX) => {
    const filters = handleGetFiltersSelected(filterName, VALUEXXX);
    const filtersTransformed = getTransformedFilters(filters);
    let newFilters = [];
    let newFiltersTransformed = [];

    if(filterName === "filterOne"){
      newFilters = handleUnsetFilterTwo(filters);
      newFiltersTransformed = handleUnsetFilterTwoTransformed(filtersTransformed)
    }else{
      newFilters = filters;
      newFiltersTransformed = filtersTransformed;
    }

    dispatch(setFilters(newFiltersTransformed));

    if (filterName !== "filterManagement") {
      handleSetFiltersSelected(newFilters);
      return handleConsultFilter(newFiltersTransformed);
    }

    return handleConsultFilter(getTransformedFilters(filtersSelected));
  };

  const handleUnsetFilterTwo = (filters) => {
    const newFilters = filters.filter((filter) => filter.NOMCOLXX !== "TARCYYYY.TARUSRRE" )
    return newFilters;
  }

  const handleUnsetFilterTwoTransformed = (filters) => {
    const newFilters = filters.filter((filter) => !filter["TARCYYYY.TARUSRRE"])
    return newFilters;
  }

  const handleResetPesistFilter = () => {
    const filters = [
      { "TARCYYYY.ESTAIDXX": { NOTIN: [101, 103] } },
      { "TARIYYYY.LIMSUPFX": { IN: ["0000-00-00"] } },
      { "TARIYYYY.LIMSUPHX": { IN: ["00:00:00"] } },
      { "ESTGEDES":{ IN: ["A TIEMPO", "VENCE HOY", "VENCIDO"]}}
    ]
    dispatch(setFilters(filters));
  };

  const handleResetFilterSelected = () => {
    setFiltersSelected([]);
  };

  const handleConsultFilter = (filters) => {
    return {
      filtersJson: JSON.stringify(filters),
    };
  };

  return {
    handleSetFilter,
    handleResetPesistFilter,
    handleResetFilterSelected,
  };
}
