import { apiSlice } from "../apiSlice";

export const informatApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMyProfile: builder.query({
      query: () => ({
        url: `/Parmpoxx/Infopers`
      }),
      providesTags: ["Create"],
    }),
    setUpdateMyprofile: builder.mutation({
      query: (parameters) => ({
        url: `/Parmpoxx/Infopers/${parameters.USRIDXXX}`,
        method: "PUT",
        body: parameters,
      }),
    })

  }),
});

export const {
  useGetMyProfileQuery,
  useSetUpdateMyprofileMutation
} = informatApiSlice;
