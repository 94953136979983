/**
 * Slice para el manejo del estado Restriccion legal
 */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  productTypes: [],
  linksData: [],
  dataProduct: null,
  descriptors: [],
};

const producxxSlice = createSlice({
  name: "producxx",
  initialState: initialState,
  reducers: {
    setProductTypes: (state, action) => {
      state.productTypes = action.payload;
    },
    setLinksData: (state, action) => {
      state.linksData = action.payload;
    },
    setDataProduct: (state, action) => {
      state.dataProduct = action.payload;
    },
    setDescriptors: (state, action) => {
      state.descriptors = action.payload;
    },
  },
});

export const { setProductTypes, setLinksData, setDataProduct, setDescriptors } = producxxSlice.actions;

export default producxxSlice.reducer;

export const getProduxctTypes = (state) => {
  return state.producxx.productTypes;
};

export const getLinksData = (state) => {
  return state.producxx.linksData;
};

export const getDataProduct = (state) => {
  return state.producxx.dataProduct;
};
export const getDescriptors = (state) => {
  return state.producxx.descriptors;
};
