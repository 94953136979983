/**
 * Slice para el manejo del estado Restriccion legal
 */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  RESIDXXX: "",
  RESAPLVU: "",
};

const reslegxxSlice = createSlice({
  name: "reslegxx",
  initialState: initialState,
  reducers: {
    setRESIDXXX: (state, action) => {
      state.RESIDXXX = action.payload;
    },
    setRESAPLVU: (state, action) => {
      state.RESAPLVU = action.payload;
    },
  },
});

export const { setRESIDXXX, setRESAPLVU } = reslegxxSlice.actions;

export default reslegxxSlice.reducer;

export const getRESIDXXX = (state) => {
  return state.reslegxx.RESIDXXX;
};

export const getRESAPLVU = (state) => {
  return state.reslegxx.RESAPLVU;
};
