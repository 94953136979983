import { apiSlice } from "../apiSlice";

export const menuApliSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    orderMenu: builder.mutation({
      query: (parameters) => ({
        url: "/orderModules",
        method: "POST",
        body: parameters,
        headers: { Modulecima: "Parmpoxx" }
      }),
    }),
    getUserTemporal: builder.query({
      query: (parameters) => ({
        url: `/getUserTemporal/${parameters.USRIDXXX}`,
      }),
    }),
    getDevices: builder.query({
      query: (parameters) => ({
        url: `/getDevices/${parameters.USRIDXXX}`,
      }),
    }),
    inactivateToken: builder.mutation({
      query: (parameters) => ({
        url: `/inactivateToken/${parameters.id}`,
        method: "delete",
      }),
    }),
  }),
});
export const {
  useOrderMenuMutation,
  useGetUserTemporalQuery,
  useGetDevicesQuery,
  useLazyGetDevicesQuery,
  useInactivateTokenMutation,
} = menuApliSlice;
