/**
 * Pagina encargada de gestionar mi perfil
 * @author sebastian rojas <sebastian.rojas@repremundo.com.co>
 */

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button, Divider, Heading, Stack, StackRow } from "codekit";
import Comunicx from "../components/Comunicx";
import Devicesx from "../components/Devicesx";
import { getIsReplaceUser } from "../libs/redux/slices/authSlice/authSlice";
import Informat from "../components/Informat";

const MyProfile = () => {
  const [tabs, setTabs] = useState("");
  const isReplaceUser = useSelector(getIsReplaceUser);

  /**
   * funcion encargada de gestionar que debe pintar acorde a la seleccion del tab
   * @returns componente a pintar
   */
  const onSelectTab = () => {
    switch (tabs) {
      case "COMMUNICATIONS":
        return <Comunicx />;
      case "DEVICES":
        return <Devicesx />;
      case "INFORMATION":
        return <Informat />;
      default:
        return "";
    }
  };

  return (
    <>
      <Box spacingX={6}>
        <Box mt={6} mb={6}>
          <Heading variant="heading-4" color="accent-600">
            Mi Perfil
          </Heading>
        </Box>
        <Divider />
        <Box mt={3} mb={3}>
          <Stack gap={8}>
            <StackRow>
              <Stack
                responsiveColumns={{
                  initial: 1,
                  small: 1,
                  large: 4,
                  extraLarge: 4,
                }}
                gap={1}
              >
                <StackRow>
                  <Button
                    variant={tabs === "INFORMATION" ? "terciary" : "secondary"}
                    type="button"
                    size="m"
                    isFull
                    onClick={() => setTabs("INFORMATION")}
                    inactive={isReplaceUser}
                  >
                    Información Personal
                  </Button>
                </StackRow>
                <StackRow>
                  <Button
                    variant={tabs === "CHANGEPASSWORD" ? "terciary" : "secondary"}
                    type="button"
                    size="m"
                    isFull
                    onClick={() => setTabs("CHANGEPASSWORD")}
                    inactive={isReplaceUser}
                  >
                    Cambiar Contraseña
                  </Button>
                </StackRow>
                <StackRow>
                  <Button
                    variant={tabs === "COMMUNICATIONS" ? "terciary" : "secondary"}
                    type="button"
                    size="m"
                    isFull
                    onClick={() => setTabs("COMMUNICATIONS")}
                  >
                    Comunicaciones
                  </Button>
                </StackRow>
                <StackRow>
                  <Button
                    variant={tabs === "DEVICES" ? "terciary" : "secondary"}
                    type="button"
                    size="m"
                    isFull
                    onClick={() => setTabs("DEVICES")}
                  >
                    Dispositivos Vinculados
                  </Button>
                </StackRow>
              </Stack>
            </StackRow>
          </Stack>
        </Box>
        {tabs !== "" ? onSelectTab() : ""}
      </Box>
    </>
  );
};

export default MyProfile;
