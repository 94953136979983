/**
 * Slice para el manejo del estado para el componente Ficha KPI
 * Ing. Carlos Fernando Castaneda Marquez <fernandocrepremundo@gmail.com>
 * 2024-05-15
 */
import { createSlice } from "@reduxjs/toolkit";

const fieldgridRowRangos = {
  VALINFXX: "",
  VALSUPXX: "",
  METRANXX: "",
};

const initialState = {
  gridRowRangos: [fieldgridRowRangos],
};

const ficinkpivSlice = createSlice({
  name: "ficinkpi",
  initialState: initialState,
  reducers: {
    setGridRowRangos: (state, action) => {
      state.gridRowRangos = action.payload;
    },
    resetValues: (state) => initialState,
  },
});


export const { setGridRowRangos } = ficinkpivSlice.actions;

export default ficinkpivSlice.reducer;

export const getAllState = (state) => state.ficinkpi;
