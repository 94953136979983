import { apiSlice } from "../apiSlice";

export const forgotApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendEmail: builder.mutation({
      query: (parameter) => ({
        url: "/sendRestoreCode",
        method: "POST",
        body: {
          USRIDXXX: parameter.email,
        },
      }),
    }),
    loginRestoreCode: builder.mutation({
      query: (credentials) => ({
        url: "/loginRestoreCode",
        method: "POST",
        body: {
          USRIDXXX: credentials.USRIDXXX,
          USRTOKRX: credentials.userCode,
        },
      }),
    }),
    restorePassword: builder.mutation({
      query: (credentials) => ({
        url: "/restorePasswordCima",
        method: "POST",
        body: {
          USRIDXXX: credentials.USRIDXXX,
          USRPASXX: credentials.password,
        },
      }),
    }),
    lockUser: builder.mutation({
      query: (credentials) => ({
        url: "/lockUser",
        method: "POST",
        body: {
          USRIDXXX: credentials.USRIDXXX,
        },
      }),
    }),
  }),
});

export const { useSendEmailMutation } = forgotApiSlice;
export const { useLoginRestoreCodeMutation } = forgotApiSlice;
export const { useRestorePasswordMutation } = forgotApiSlice;
export const { useLockUserMutation } = forgotApiSlice;
