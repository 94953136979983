import { useSelector } from "react-redux";
import { useGetClasificationFilterQuery } from "../../../libs/redux/slices/Graficsx/graficsxApiSlice";
import { getDataUser } from "../../../libs/redux/slices/authSlice/authSlice";
import { useEffect, useState } from "react";
import { useSelect } from "../useSelect";

export function useClasificationsFilter({ MODIDXXX, PROIDXXX }) {
  const [data, setData] = useState([]);
  const { USRIDXXX, CARIDXXX } = useSelector(getDataUser);
  const { data: clasifications } = useGetClasificationFilterQuery({ CARIDXXX, USRIDXXX });

  const { getDataForSelect } = useSelect();

  useEffect(() => {
    if (clasifications) {
      const newClasifications = getDataForSelect(clasifications.data, "CLATARID", "CLATARDE");
      setData(newClasifications);
    }
  // eslint-disable-next-line
  }, []);

  return [data];
}
