import { Box, Divider, Input, InputMask, Label, Stack, StackRow } from "codekit";
import { useEffect, useState } from "react";
import { useGetMyProfileQuery, useSetUpdateMyprofileMutation } from "../../libs/redux/slices/InformatSlice/informatApiSlice";
import { useSelector } from "react-redux";
import { getDataUser } from "../../libs/redux/slices/authSlice/authSlice";
import { TableLoader } from "codekit";
import { Flexbox } from "codekit";
import { Button } from "codekit";

const Informat = () => {
  const { data, isLoading } = useGetMyProfileQuery();
  const { CARDESXX } = useSelector(getDataUser);
  const [numCell, setNumCell] = useState('');
  const [saveData, { isLoading: loadSave }] = useSetUpdateMyprofileMutation();

  const saveInformation = async () => {
    await saveData({ USRIDXXX: data[0].CIMS0001.USRIDXXX, USRCELUL: numCell })
      .unwrap()
      .then(() => {
      })
      .catch();
  }

  useEffect(() => {
    if (data) {
      setNumCell(data[0].CIMS0001.USRCELUL);
    }
  }, [data, setNumCell])
  return isLoading ? (
    <TableLoader />
  ) : (
    <Box mb={8}>
      <Stack
        gap={4}
        responsiveColumns={{
          initial: 1,
          small: 2,
          large: 3,
          extraLarge: 3,
        }}
      >
        <StackRow>
          <Label>Tipo de usuario</Label>
          <Input
            value={data[0].CIMS0001.USRTIPOX}
            Disabled
          />
        </StackRow>
        <StackRow>
          <Label>Cédula</Label>
          <Input
            value={data[0].CIMS0001.USRIDXXX}
            Disabled
          />
        </StackRow>
        <StackRow>
          <Label>Primer nombre</Label>
          <Input
            value={data[0].CIMS0001.USRPNOMX}
            Disabled
          />
        </StackRow>
        <StackRow>
          <Label>Otros nombre</Label>
          <Input
            value={data[0].CIMS0001.USRSNOMX}
            Disabled
          />
        </StackRow>
        <StackRow>
          <Label>Primer apellido</Label>
          <Input
            value={data[0].CIMS0001.USRPAPEX}
            Disabled
          />
        </StackRow>
        <StackRow>
          <Label>Segundo apellido</Label>
          <Input
            value={data[0].CIMS0001.USRSEPEX}
            Disabled
          />
        </StackRow>
        <StackRow>
          <Label>Correo electrónico</Label>
          <Input
            value={data[0].CIMS0001.USREMAXX}
            Disabled
          />
        </StackRow>
        <StackRow>
          <Label>Celular</Label>
          <InputMask
            mask="+99 9999999999"
            placeholder="+99 9999999999"
            onChange={(event) => {
              setNumCell(event.target.value);
            }}
            value={numCell}
          />
        </StackRow>
        <StackRow>
          <Label>Cargo</Label>
          <Input
            value={CARDESXX}
            Disabled
          />
        </StackRow>
      </Stack>
      <Box mb={8} mt={6}>
        <Divider text="Grupos de Trabajo" color="accent-600" />
        <Box mt={5} bg="neutral-200" spacing={3}>
          <Stack
            gap={4}
            responsiveColumns={{
              initial: 1,
              small: 2,
              large: 2,
              extraLarge: 2,
            }}
          >
            <StackRow>
              <Label>ID Grupo de Trabajo</Label>
            </StackRow>
            <StackRow>
              <Label>Nombre Grupo de Trabajo</Label>
            </StackRow>
          </Stack>
        </Box>
        {
          data[0].CIMS0040.map((row, index) => {
            const colorsBox = index % 2 === 0 ? "neutral-100" : "neutral-200";
            return (
              <Box key={index} bg={colorsBox} spacingX={5} spacingY={2}>
                <Stack
                  gap={4}
                  responsiveColumns={{
                    initial: 1,
                    small: 2,
                    large: 2,
                    extraLarge: 2,
                  }}
                >
                  <StackRow alignItems="center">
                    <Label weight="semibold">{row.GRUPIDXX}</Label>
                  </StackRow>
                  <StackRow alignItems="center">
                    <Label weight="semibold">{row.GRUPDESX}</Label>
                  </StackRow>
                </Stack>
              </Box>
            )
          })
        }
      </Box>
      <Box mb={8} mt={6}>
        <Divider text="Sucursales" color="accent-600" />
        <Box mt={5} bg="neutral-200" spacing={3}>
          <Stack
            gap={4}
            responsiveColumns={{
              initial: 1,
              small: 2,
              large: 2,
              extraLarge: 2,
            }}
          >
            <StackRow>
              <Label>ID Sucursal</Label>
            </StackRow>
            <StackRow>
              <Label>Nombre Sucursal</Label>
            </StackRow>
          </Stack>
        </Box>
        {
          data[0].CIMS0064.map((row, index) => {
            const colorsBox = index % 2 === 0 ? "neutral-100" : "neutral-200";
            return (
              <Box key={index} bg={colorsBox} spacingX={5} spacingY={2}>
                <Stack
                  gap={4}
                  responsiveColumns={{
                    initial: 1,
                    small: 2,
                    large: 2,
                    extraLarge: 2,
                  }}
                >
                  <StackRow alignItems="center">
                    <Label weight="semibold">{row.SUCIDXXX}</Label>
                  </StackRow>
                  <StackRow alignItems="center">
                    <Label weight="semibold">{row.SUCDESXX}</Label>
                  </StackRow>
                </Stack>
              </Box>
            )
          })
        }
      </Box>
      <Flexbox justifyContent="end">
        <Button variant="primary-bold" onClick={() => saveInformation()} isLoading={isLoading || loadSave}>
          Guardar
        </Button>
      </Flexbox>
    </Box>
  )

}

export default Informat;
