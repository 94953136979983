/**
 * Slice para el maneja del estado para el componente Tracking de Importaciones
 */
import { createSlice } from "@reduxjs/toolkit";

//Estado inicial Pestañas Por Operacion
const statusShowView = {
  RESULTADOS: true,
  GENERALES: false,
};

const initialState = {
  statusShowView,
};

const trackimpSlice = createSlice({
  name: "trackimp",
  initialState: initialState,
  reducers: {
    setStatusShowView: (state, action) => {
      state.statusShowView = action.payload;
    },
  },
});

export const { setStatusShowView } = trackimpSlice.actions;

export default trackimpSlice.reducer;

export const gatAllState = (state) => {
  return state.trackimp;
};
