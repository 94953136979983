/**
 * Slice para el maneja del estado para el componente Saldos Documento de Transporte
 */
import { createSlice } from "@reduxjs/toolkit";

//Estado inicial de cada pestaña
const statusShowView = {
  general: true,
  eta: false,
  traffic: false,
  expiration: false,
  release: false,
};

const initialState = {
  statusShowView,
  idHomologationAgen: 0,
  idHomologationPackaging: 0,
};

const saldoctrSlice = createSlice({
  name: "saldoctr",
  initialState: initialState,
  reducers: {
    setStatusShowView: (state, action) => {
      state.statusShowView = action.payload;
    },
    setIdHomologationAgen: (state, action) => {
      state.idHomologationAgen = action.payload;
    },
    setIdHomologationPackaging: (state, action) => {
      state.idHomologationPackaging = action.payload;
    },
  },
});

export const { setStatusShowView, setIdHomologationAgen, setIdHomologationPackaging } = saldoctrSlice.actions;

export default saldoctrSlice.reducer;

export const gatAllState = (state) => {
  return state.saldoctr;
};

export const gatIdHomologationAgen = (state) => {
  return state.saldoctr.idHomologationAgen;
};

export const gatIdHomologationPackaging = (state) => {
  return state.saldoctr.idHomologationPackaging;
};
